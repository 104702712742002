<template>
  <div class="px-2 switcher-wrapper">
    <sdButton
      class="switch-btn table-view"
      :class="!isGraph && 'icon-white'"
      type="primary"
      :outlined="isGraph"
      @click="click('tableView')"
    >
      {{ getButtonText(props.isPortfolio) }}
    </sdButton>
    <sdButton
      class="switch-btn graph-view"
      :class="isGraph && 'icon-white'"
      type="primary"
      :outlined="!isGraph"
      @click="click('graphView')"
    >
      {{ $t("generic.buttons.funds_view") }}
    </sdButton>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  isPortfolio: {
    type: Number,
    default: 0
  },
})

const emit = defineEmits(['changeViewmode']);

const isGraph = ref(false);

const click = async (view) => {
  if (view === "tableView") {
    isGraph.value = false;
  } else {
    isGraph.value = true;
  }
  emit('changeViewmode', isGraph.value);
};

const getButtonText = (value) => {
  switch(value) {
    case 0:
      return t("generic.buttons.asset_basket");
    case 1:
      return t("generic.buttons.asset_portfolio");
    case 2:
      return t("generic.buttons.portfolios");
    default:
      return t("generic.buttons.asset_basket"); 
  }
};
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.switcher-wrapper {
  margin-bottom: 24px;
  display: flex;
  .switch-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 80%;
    padding: 16px 0 !important;
    &:focus {
      background-color: $text-light-color;
    }
    &:hover {
      background-color: $text-light-color;
      color: white;
      border: none;
    }
    &.table-view {
      border-top-left-radius: 30px  !important;
      border-bottom-left-radius: 30px  !important;
    }
    &.graph-view {
      border-top-right-radius: 30px  !important;
      border-bottom-right-radius: 30px  !important;
    }
  }
}
</style>
